/* Basic styling for the home page */

body {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  .home-container {
    text-align: center;
    padding: 50px;
    background-color: #f4f4f9;
    min-height: 100vh;
  }
  
  .home-header h1 {
    font-size: 3rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .home-header p {
    font-size: 1.2rem;
    color: #666;
  }
  
  .home-links h2 {
    font-size: 2rem;
    color: #333;
    margin-top: 40px;
  }
  
  .home-links ul {
    list-style-type: none;
    padding: 0;
  }
  
  .home-links li {
    margin: 20px 0;
  }
  
  .home-links a {
    font-size: 1.5rem;
    color: #007bff;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .home-links a:hover {
    color: #0056b3;
  }
  
  .home-footer {
    margin-top: 50px;
    color: #aaa;
    font-size: 0.9rem;
  }
  